import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import './index.css'

import {Button} from 'antd-mobile';


const data = [
        {
            "title": "社区综合管理服务平台",
            items: [{
                id: 1,
                title: "社区大脑（含报警数据治理）",
            },
                {
                    id: 2,
                    title: "身份识别",
                }
            ]
        },
        {
            title: "智慧康养",
            items: [{
                id: 3,
                title: "无线网络生物探测",
            },
                {
                    id: 4,
                    title: "24小时智能问诊",
                },
                {
                    id: 5,
                    title: "AI医疗语音助手",
                },
                {
                    id: 6,
                    title: "眼底智能筛查",
                },
                {
                    id: 7,
                    title: "智慧健康小屋",
                },
            ]
        },
        {
            title: "智慧生活",
            items: [{
                id: 8,
                title: "智能垃圾分类",
            },
                {
                    id: 9,
                    title: "垃圾分类体感游戏",
                },
                {
                    id: 10,
                    title: "物业服务智能响应",
                },
                {
                    id: 11,
                    title: "智慧出行",
                },
                {
                    id: 12,
                    title: "微信智能互动",
                },
                {
                    id: 13,
                    title: "公共服务信息发布",
                },
                {
                    id: 14,
                    title: "街面自动清扫车",
                }
            ]
        },
        {
            title: "智慧文化",
            items: [{
                id: 15,
                title: "智能服务机器人",
            },
                {
                    id: 16,
                    title: "城市智慧书房",
                },
                {
                    id: 17,
                    title: "文化云盒",
                },
            ]
        },
        {
            title: "智慧运动",
            items: [{
                id: 18,
                title: "小区智能步道",
            },
                {
                    id: 19,
                    title: "体魔方",
                }]
        },
        {
            title: "智慧商业",
            items: [{
                id: 20,
                title: "无人商业",
            },
                {
                    id: 21,
                    title: "智慧菜场",
                },
                {
                    id: 22,
                    title: "金融服务体验",
                }]
        },
        {
            title: "智慧政务",
            items:
                [{
                    id: 23,
                    title: "智能政务咨询预约",
                },
                    {
                        id: 24,
                        title: "数字电视智能AI政务服务",
                    }]
        },
        {
            title: "智慧安防",
            items: [{
                id: 25,
                title: "门店招牌检测与警告",
            },
                {
                    id: 26,
                    title: "实时监控和感知街面状态",
                },
                {
                    id: 27,
                    title: "电梯监控",
                },
                {
                    id: 28,
                    title: "电弧火花",
                },
                {
                    id: 29,
                    title: "社区智能安防",
                }]
        },
        {
            title: "基础建设",
            items:
                [{
                    id: 30,
                    title: "5G信号覆盖",
                },
                ]
        }
    ]
;

const IndexPage = () => (
    <Layout>
        <SEO title="慧居家园北新泾AI+HOME"/>
        {/*<h1 className='head'>慧居家园北新泾</h1>*/}
        {/*<h1 className='aiHome'>AI+HOME</h1>*/}
        <div className='content'>
            <section className='firstSection'>
                <h3 className='title'>
                    {/*<span className="left"/>*/}
                    <span className='item_title'>{data[0].title}</span>
                    {/*<span className="right"/>*/}
                </h3>
                <div className='btnList'>
                    {data[0].items.map((inner) => {
                        return <p><Link to={"/article?id=" + inner.id} className='btn'>{inner.title}</Link>
                        </p>
                    })}
                </div>
            </section>
            {
                data.map((item) => {
                    if (item.title.indexOf("社区综合管理服务平台") >= 0) {
                        return "";
                    }
                    return (
                        <section className='section'>
                            <h3 className='title'>
                                <span className="left"/><span className='item_title'>{item.title}</span><span className="right" />
                            </h3>
                            <div className='btnList2'>
                                {item.items.map((inner) => {
                                    return <p className='btnWrapper'><Link to={"/article?id=" + inner.id}
                                                                           className='btn'>{inner.title}</Link>
                                    </p>
                                })}
                            </div>
                        </section>)
                })
            }
        </div>
    </Layout>
)

export default IndexPage
